import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { OutboundLink } from "gatsby-plugin-google-gtag";
const ProductHighlight = () => {
  return (
    <>
      <Row>
        <Col sm={6} md={4} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B000R9COOY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B000R9COOY&linkCode=as2&tag=exclusiveg01c-20&linkId=0f228f22de37bbae534a812ed3992aa2`}
          >
            <StaticImage src="../images/dr_pepper_original_12_pack_cans.jpg" alt="dr_pepper_original_12_pack_cans" placeholder="blurred" width={400} height={400} />
            <br />
            Dr Pepper, 12 fl oz cans (Pack of 12)
          </OutboundLink>
        </Col>
        <Col sm={6} md={4} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B084WPYLML/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B084WPYLML&linkCode=as2&tag=exclusiveg01c-20&linkId=c37684f014830b02eb95ce28c6b5b8fa`}
          >
            <StaticImage src="../images/dr_pepper_cream_soda_12_pack_cans.jpg" alt="dr_pepper_cream_soda_12_pack_cans" placeholder="blurred" width={400} height={400} />
            <br /> Dr Pepper &amp; Cream Soda, 12 fl oz cans, 12 pack
          </OutboundLink>
        </Col>
        <Col sm={6} md={4} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B000R9AKZO/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B000R9AKZO&linkCode=as2&tag=exclusiveg01c-20&linkId=d0e84eff6dfb7dad380c7a17902cc93d`}
          >
            <StaticImage src="../images/dr_pepper_diet_12_pack_cans.jpg" alt="dr_pepper_diet_12_pack_cans" placeholder="blurred" width={400} height={400} />
            <br />
            Diet Dr Pepper, 12 fl oz cans (Pack of 12)
          </OutboundLink>
        </Col>
        <Col sm={6} md={4} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B01FHTGFL6/ref=as_li_tl?ie=UTF8&tag=exclusiveg01c-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01FHTGFL6&linkId=b58b3b67b9e0a1357319dc060282e1de`}
          >
            <StaticImage src="../images/dr_pepper_original_8_pack_bottle.jpg" alt="dr_pepper_original_8_pack_bottle" placeholder="blurred" width={400} height={400} />
            <br /> Dr Pepper, 12 fl oz bottles (Pack of 8)
          </OutboundLink>
        </Col>
        <Col sm={6} md={4} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B08XXGB5NK/ref=as_li_tl?ie=UTF8&tag=exclusiveg01c-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B08XXGB5NK&linkId=8c034d96c664608808c0a500b16ea2e0`}
          >
            <StaticImage src="../images/dr_pepper_zero_12_pack_cans.jpg" alt="dr_pepper_zero_12_pack_cans" placeholder="blurred" width={400} height={400} />
            <br />
            Dr Pepper Zero Sugar Soda, 12 fl oz cans, 12 pack
          </OutboundLink>
        </Col>
        <Col sm={6} md={4} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B00FFJ3ZUI/ref=as_li_tl?ie=UTF8&tag=exclusiveg01c-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00FFJ3ZUI&linkId=1a06805f983503f89be0af7d7f8fc8a4`}
          >
            <StaticImage src="../images/dr_pepper_diet_8_pack_bottle.jpg" alt="dr_pepper_diet_8_pack_bottle" placeholder="blurred" width={400} height={400} />
            <br />
            Diet Dr Pepper, 12 fl oz bottles (Pack of 8)
          </OutboundLink>
        </Col>
      </Row>
    </>
  );
};
export default ProductHighlight;
